export function formatDate(date) {
  const day = date.getDate();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  const month = months[date.getMonth()];
  const year = date.getYear() + 1900;
  const hours24 = date.getHours();
  const hours = hours24 > 12 ? hours24 - 12 : hours24;
  const am_pm = hours24 > 12 ? "pm" : "am";
  const minutes = date
    .getMinutes()
    .toString()
    .padStart(2, "0");
  return `${day} ${month} ${year} ${hours}:${minutes} ${am_pm}`;
}

export function humanizeDate(date) {
  const now = new Date();
  const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  if (weeks > 1) {
    return `${weeks} weeks ago`;
  } else if (weeks === 1) {
    return `1 week ago`;
  } else if (days > 1) {
    return `${days} days ago`;
  } else if (days === 1) {
    return `1 day ago`;
  } else if (hours > 1) {
    return `${hours} hours ago`;
  } else if (hours === 1) {
    return `1 hour ago`;
  } else if (minutes > 1) {
    return `${minutes} minutes ago`;
  } else if (minutes === 1) {
    return `1 minute ago`;
  } else if (seconds > 1) {
    return `seconds ago`;
  } else {
    return `just now`;
  }
}
